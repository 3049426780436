<template>
  <custom-button-group
    v-model="selectedLocale"
    :options="entityOptions"
    class="h-[38px]"
    button-class="flex items-center font-semibold"
  />
</template>

<script setup lang="ts">
import CustomButtonGroup from '~/ui/buttons/CustomButtonGroup.vue'

const props = defineProps({
  modelValue: {
    type: String as PropType<string | null>,
    default: null,
  },
  isGeneral: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: string): void}>()

const { locales, setLocale, locale: currentLocale } = useI18n()

const route = useRoute()

const router = useRouter()

const initialLocale = computed(() => {
  const urlLocale = route.query.lang
  if (urlLocale) {
    return urlLocale as string
  }
  return props.modelValue || currentLocale.value
})

const updateURL = (newLang: string) => {
  router.replace({
    query: {
      ...route.query,
      lang: newLang,
    },
  })
}

const selectedLocale = computed({
  get: () => initialLocale.value,
  set: value => {
    if (!props.isGeneral) {
      if (route.query.lang) {
        updateURL(value)
      }
      setLocale(value)
      return
    }
    emits('update:modelValue', value)
  },
})

const entityOptions = computed(() => locales.value.map(locale => {
  let localeString = ''

  if (typeof locale === 'string') {
    localeString = locale
  } else {
    localeString = locale.code
  }

  return { value: localeString, label: localeString.toUpperCase(), icon: `flags/${localeString}`, iconFilled: true, iconClass: 'text-[24px]' }
}))

onMounted(() => {
  if (route.query.lang) {
    setLocale(route.query.lang as string)
  }
})

</script>
