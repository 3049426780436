<template>
  <custom-button-group
    v-model="selectedCurrency"
    :options="entityOptions"
    class="h-[38px]"
    button-class="flex items-center font-semibold"
    :is-adaptive="false"
  />
</template>

<script setup lang="ts">
import CustomButtonGroup from '~/ui/buttons/CustomButtonGroup.vue'
import { CURRENCY } from '~/common/constants/money/Currency'
import { useAppStateStore } from '~/store/app'
import { Currency } from '~/common/types/money/Currency'

const props = defineProps({
  modelValue: {
    type: String as PropType<Currency | null>,
    default: null,
  },
  isGeneral: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: string): void}>()

const appStateStore = useAppStateStore()

const { t } = useI18n()

const currencies = Object.values(CURRENCY)

const route = useRoute()

const router = useRouter()

const initialCurrency = computed(() => {
  const urlCurrency = route.query.currency
  return urlCurrency ? (urlCurrency as Currency) : props.modelValue || appStateStore.currency
})

const entityOptions = computed(() => currencies.map(currency => ({ value: currency, label: t(`currency.options.${currency}`) })))

const updateURL = (newCur: Currency) => {
  router.replace({
    query: {
      ...route.query,
      currency: newCur,
    },
  })
}

const selectedCurrency = computed({
  get: () => initialCurrency.value,
  set: value => {
    if (!props.isGeneral) {
      if (route.query.currency) {
        updateURL(value)
      }
      appStateStore.setCurrency(value)
      return
    }
    emits('update:modelValue', value)
  },
})

watch(
  () => route.query.currency,
  newCurrency => {
    if (newCurrency) {
      appStateStore.setCurrency(newCurrency as Currency)
    }
  },
)
</script>
